import React from 'react'
import week from "../assets/HomeIMG/1-week.webp"
import month from "../assets/HomeIMG/2-month.webp"
import quarter from "../assets/HomeIMG/3-quarter.webp"
import year from "../assets/HomeIMG/4-year.webp"

const InsightSection = () => {
  return (
    <section class="relative py-20 2xl:py-30 overflow-hidden">
        <div class="absolute bottom-0 inset-x-0 h-3/5 w-2full -ml-64 -mb-12 bg-gradient-zospace-2 transform -rotate-6"></div> 
        <div class="container px-4 mx-auto">
            <div class="flex flex-wrap -mx-6 lg:-mx-8">
                <div class="w-full sm:w-1/2 lg:w-1/4 px-6">
                     <img class="mx-auto" src={week} alt="week"/>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/4 px-6 md:mt-0 mt-8">
                     <img class="mx-auto" src={month}alt="month"/>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/4 px-6 lg:mt-0 mt-8">
                     <img class="mx-auto" src={quarter} alt="Quarter"/>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/4 px-6 lg:mt-0 mt-8">
                     <img class="mx-auto" src={year} alt="Year"/>
                </div>
            </div>
        </div>
      </section>
  )
}

export default InsightSection