import React from 'react';
import task1 from "../assets/HomeIMG/icon/1-task-schedule.svg";
import task2 from "../assets/HomeIMG/icon/2-calendar.svg";
import task3 from "../assets/HomeIMG/icon/3-find-partners.svg";
import task4 from "../assets/HomeIMG/icon/4-challenge.svg";
import task6 from "../assets/HomeIMG/icon/5-task-sharing.svg";
import task7 from "../assets/HomeIMG/icon/6-journal-book.svg";
import task8 from "../assets/HomeIMG/icon/7-all-in-one.svg";
import task9 from "../assets/HomeIMG/icon/8-goal.svg";

const features = [
  {
    icon: task1,
    title: "Intelligent Task Scheduling",
    description: "Harness the power of AI to effortlessly schedule your daily tasks, ensuring maximum productivity and efficiency.",
  },
  {
    icon: task2,
    title: "Seamless Calendar Integration",
    description: "Sync your tasks with your calendar to stay on top of your schedule and never miss an important appointment or deadline again.",
  },
  {
    icon: task3,
    title: "Find Accountability Partners",
    description: "Connect with like-minded individuals who share your goals and aspirations. Cultivate a supportive community that motivates you to stay focused and accountable.",
  },
  {
    icon: task4,
    title: "Challenge Yourself",
    description: "Take part in exciting challenges and competitions to build healthy habits and achieve milestones alongside your friends and family.",
  },
  {
    icon: task6,
    title: "Efficient Task Sharing",
    description: "Easily share tasks with your family or roommates, eliminating misunderstandings and ensuring that chores are completed without hassle.",
  },
  {
    icon: task7,
    title: "Integrated Journaling",
    description: "Record your thoughts, ideas, and reflections through voice or written journal entries. Track your progress, celebrate your successes, and learn from your experiences.",
  },
  {
    icon: task8,
    title: "All-in-One Organization",
    description: "With Laksha everything you need to stay organized is in one place. Say goodbye to scattered to-do lists and hello to streamlined productivity.",
  },
  {
    icon: task9,
    title: "Achieve Your Goals",
    description: "Empower yourself to build lasting habits and accomplish your goals with the support of Laksha. Stay motivated, stay focused, and keep achieving.",
  },
];

const FeaturesSection = () => {
  return (
      <section className="relative py-20 overflow-hidden">
        <div className="absolute h-40 w-40 bg-red bottom-0 right-0 -mr-20 -mb-20 rounded-full"></div>
        <div className="container px-4 mx-auto">
          <div>
            <div className="flex flex-wrap -mx-6 lg:-mx-8">
              {features.map((feature, index) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/4 px-6 lg:px-8 mb-10 h-100">
                  <div className="p-3 flex-card h-100" style={{ border: "1px solid rgba(42, 29, 48, .16)", borderRadius: "12px", height: "346px" }}>
                    <span className="flex mb-6 justify-center items-center w-20 h-20 bg-light-gray rounded-lg">
                      <img className="mx-auto" src={feature.icon} alt={feature.title.toLowerCase().replace(/\s+/g, '-')} />
                    </span>
                    <div className="pb-px mb-8 bg-gray-500" style={{backgroundColor: "rgba(42, 29, 48, .16)"}}></div>
                    <h3 className="mt-5 mb-6 text-lg font-bold font-heading text-black-15">{feature.title}</h3>
                    <p className="text-md text-gray">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
  );
};

export default FeaturesSection;
