import React, { useEffect } from "react";
import GoogleImage from "../../assets/images/icons/google_icon.svg";
import FcImage from "../../assets/images/icons/fb_icon.png";

import { useGoogleLogin } from '@react-oauth/google';
import { useSocialApis } from "./SocialApis";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogIn } from "../../Store/Slices/AuthSlice";
import { LoginSocialFacebook } from "reactjs-social-login";



const SocialLogins = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checkUserInfoThroughMedia, socialMediaLogin } = useSocialApis();




  const checkUserInfo = (userInfo) => {
    checkUserInfoThroughMedia({ media_id: userInfo?.id, email: userInfo?.email }).then((res) => {
      if (res.status === 200) {

        socialMediaLogin(userInfo).then((res) => {
          if (res.status === 200) {
            localStorage.setItem("user", JSON.stringify(res.data));
            dispatch(LogIn(res.data));
            localStorage.setItem("user_token", res.data.token);
            navigate("/home");
          }
        }).catch((error) => {
          console.log(error, "errror")
        })
      } else {
        navigate("/signup", { state: userInfo })
      }
    }).catch((error) => {
      console.log(error);
    })
  }



  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse?.access_token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {

          const user_Info = {
            first_name: data.given_name,
            last_name: data.family_name,
            id: data.id,
            email: data.email,
            auth_type: 'google',
          }

          checkUserInfo(user_Info);

        })
        .catch((error) => console.error('Error fetching user info', error));
      return localStorage.setItem("access_token", tokenResponse?.access_token)
    },
  });



  // const facebookLogin = (facebookloginData) => {
  //   if (facebookloginData?.data) {
  //     const user_Info = {
  //       first_name: facebookloginData?.data.first_name,
  //       last_name: facebookloginData?.data.last_name,
  //       id: facebookloginData?.data.id,
  //       email: facebookloginData?.data.email,
  //       auth_type: 'facebook',
  //     }
  //     checkUserInfo(user_Info);
  //   }
  // }






  const createEventInCalendar = (eventDetails) => {
    // Construct the event request body with the event details
    const requestBody = {
      summary: "Event Summary",
      description: "Event Description",
      start: {
        dateTime: "2023-11-10T10:00:00",
        timeZone: "utc",
      },
      end: {
        dateTime: "2023-11-10T12:00:00",
        timeZone: "utc",
      },
    };

    fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to create event');
        }
      })
      .then((data) => {
        console.log("Event created:", data);
      })
      .catch((error) => console.error('Error creating event:', error));
  };








  return (
    <div className="hidden lg:block md:block">
      <div className="social-icons flex w-full justify-center  ">

        <div onClick={() => {
          login()
        }} className="social-item-icon mx-2 w-12 h-12 rounded-full flex items-center justify-center shadow-md ">
          <img
            src={GoogleImage}
            alt=""
            className="w-full h-full p-2 object-cover"
          />
        </div>
        {/* <div className="social-item-icon mx-2 w-12 h-12 rounded-full flex items-center justify-center shadow-md ">


          <LoginSocialFacebook
            appId="1042631276863826"
            onResolve={(resolve) => {
              facebookLogin(resolve)
            }}
            onReject={(error) => {
              console.log("res", error)
            }}
          >
            <img
              src={FcImage}
              alt=""
              className="w-full h-full p-2 object-cover"
            />
          </LoginSocialFacebook>


        </div> */}
      </div>
    </div>
  );
};

export default SocialLogins;
