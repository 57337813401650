import React from 'react';
import FeaturesSection from './FeaturesSection';
import ApplicationSection from './ApplicationSection';
import InsightSection from './InsightSection';
import FooterSection from './FooterSection';
import LOGOIMG from "../assets/HomeIMG/logo.png";
import heromockup from "../assets/HomeIMG/hero-mockup.webp";
import Group from "../assets/HomeIMG/Group-4.svg";
import { useNavigate } from 'react-router-dom';
import store from "../assets/Element/app-store-dark.svg"
import play from "../assets/Element/google-play-dark.svg"

function WelcomePage() {
    let navigate = useNavigate();
    const handlePlayStore = () => {
        const playStoreURL = "https://play.google.com";
        window.open(playStoreURL, "_blank");
    };

    const handleAppStore = () => {
        const appStoreURL = "https://apple.com";
        window.open(appStoreURL, "_blank");
    };
    return (
        <>
            <style>
                {`
                    .custom-font-size {
                        font-size: 6rem; /* Default for larger screens */
                        line-height: 1;
                    }
                    @media (max-width: 1024px) {
                        .custom-font-size {
                            font-size: 50px; /* Adjust for tablets */
                        }
                    }
                    @media (max-width: 768px) {
                        .custom-font-size {
                            font-size: 60px; /* Adjust for mobile devices */
                        }
                    }
                    @media (max-width: 480px) {
                        .custom-font-size {
                            font-size: 50px; /* Adjust for smaller mobile devices */
                        }
                    }
                `}
            </style>
            <body className="antialiased bg-body text-body font-body" style={{ width: '100vw', height: '100vh', overflowX: "hidden" }}>
                <section className="relative pb-20 overflow-hidden" style={{ backgroundColor: "#fefdfc" }}>
                    <nav className="relative">
                        <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
                            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center justify-between">
                                <a className="text-2xl text-white font-bold">
                                    <img src={LOGOIMG} alt="" />
                                </a>
                            </div>
                            <div className='absolute top-4 right-4'>
                                <button
                                    className="text-white font-bold bg-orange-500 px-4 py-2 rounded"
                                    onClick={() => navigate("/splashScreen")}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </nav>
                    <div className="relative container px-4 pt-12 md:pt-20 mx-auto">
                        <div className="hidden 2xl:block absolute bottom-0 w-3/5 mb-40 h-2 border-b border-white"></div>
                        <div className="relative flex flex-wrap -mx-4">
                            <div className="w-full lg:w-1/2 2xl:w-2/5 px-4 mb-12 mb:mb-0">
                                <span className="text-xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-200 to-orange-600">Welcome to Lakshya</span>
                                <p className="max-w-lg lg:max-w-md 2xl:max-w-none mt-7 mb-12 mb:mb-20 text-black-15 font-bold font-heading text-[50px] md:text-[70px] lg:text-[70px] leading-none">
                                    Your Ultimate Productivity Companion
                                </p>
                                <p className="text-lg text-gray opacity-90">Lakshya is not just another productivity app. It's your personal AI-powered assistant designed to revolutionize the way you organize, plan, and achieve your daily tasks and long-term goals.</p>
                                <div style={{ display: "flex", marginTop: "21px" }} >
                                    <div class="w-full md:w-auto mb-3 md:mb-0 md:mr-4">
                                        <a href="https://apps.apple.com/in/app/lakshyaapp/id6498630642" target="_blank" rel="noopener noreferrer">
                                            <img class="mx-auto" src={store} alt="" />
                                        </a>
                                    </div>
                                    <div class="w-full md:w-auto">
                                        <a href="https://play.google.com/store/apps/details?id=com.promettur.dyscorse" target="_blank" rel="noopener noreferrer">
                                            <img className="mx-auto" src={play} alt="Google Play Store" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-1/2 2xl:w-3/5 px-4">
                                <img className="object-contain w-full h-auto" src={heromockup} alt="hero-mockup" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
                        <nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
                            <div className="flex items-center mb-16 pr-6">
                                <a className="ml-10 mr-auto text-2xl text-gray-800 font-bold" href="#">
                                    <img src={Group} alt="" width="300" />
                                </a>
                            </div>
                            <div>
                                <ul>
                                    <li className="mb-1 px-10">
                                        <a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="#">Product</a>
                                    </li>
                                    <li className="mb-1 px-10">
                                        <a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="#">Story</a>
                                    </li>
                                    <li className="mb-1 px-10">
                                        <a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="#">Features</a>
                                    </li>
                                    <li className="mb-1 px-10">
                                        <a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="#">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-auto px-10">
                                <div className="pt-6">
                                    <a className="block mb-4 py-4 px-12 text-gray-800 text-center font-bold border border-gray-50 hover:border-gray-100 rounded-full" href="#">Write us</a>
                                    <a className="block py-4 px-12 text-white text-center font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200" href="#">Schedule call</a>
                                </div>
                                <p className="mt-6 mb-4 text-lg text-center">
                                    <span>2021 © Zospace. All rights reserved.</span>
                                </p>
                            </div>
                        </nav>
                    </div>
                </section>
                <FeaturesSection />
                <ApplicationSection />
                <InsightSection />
                <FooterSection />
            </body>
        </>
    );
}

export default WelcomePage;
