import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import AdminLayout from "../layout/AdminLayout";
import Home from "../pages/admin/Home";
import Signup from "../pages/SignUp/Signup";
import Reset from "../pages/Reset/Reset";
import TaskPage from "../pages/admin/Task/TaskPage";
import Chat from "../pages/admin/Chat/Chat";
import Journal from "../pages/admin/Journal/Journal";
import Activity from "../pages/admin/Activity/Activity";
import Setting from "../pages/admin/Setting/Setting";
import Profile from "../pages/admin/Profile/Profile";
import SplashScreen from "../pages/SplashScreens/SplashScreen";
import Dummy from "./Dummy";
import Challanegs from "../pages/admin/Challenges/Challanegs";
import ChallangeItem from "../pages/admin/Challenges/ChallangeItem";
import ChallangePostDetail from "../pages/admin/Challenges/ChallangePostDetail";
import WelcomePage from "../pages/WelcomePage";

const PageRouter = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let userLoggedInStatus = JSON.parse(localStorage?.getItem("user"));
    if (userLoggedInStatus) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, []);
  return (
    <Routes>
      <Route exact path="/" element={<WelcomePage />} />
      <Route exact path="/login" element={<Login />} />
      <Route path="/splashScreen" element={<SplashScreen />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/reset" element={<Reset />} />
      <Route exact path="/dummy" element={<Dummy />} />
      <Route element={<AdminLayout />}>
        <Route path="/home" element={<Home />} />
        <Route exact path="/task" element={<TaskPage />} />
        <Route path="/activity" element={<Activity />} />
        <Route exact path="/challenges" element={<Challanegs />} />
        <Route exact path="/challenges/:id" element={<ChallangeItem />} />
        <Route exact path="/postDetail/:id" element={<ChallangePostDetail />} />
        <Route exact path="/journal" element={<Journal />} />

        <Route path="/setting" element={<Setting />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:id" element={<Profile />} />

      </Route>
    </Routes>
  );
};

export default PageRouter;
