import React from 'react'
import store from "../assets/Element/app-store-dark.svg"
import play from "../assets/Element/google-play-dark.svg"
import downloadmockup from "../assets/HomeIMG/download-mockup.webp"

const ApplicationSection = () => {
    const handlePlayStore = () => {
        const playStoreURL = "https://play.google.com";
        window.open(playStoreURL, "_blank");
    };

    const handleAppStore = () => {
        const appStoreURL = "https://apple.com";
        window.open(appStoreURL, "_blank");
    };
    return (
        <section className="pt-24 relative pt-20 2xl:py-30 overflow-hidden bg-light-yellow" style={{
            borderBottom: "1px solid rgba(42,29,48,.16)",
            backgroundColor: "#fcf5ee"
        }}
        >
            <div class="container px-4 mx-auto">
                <div class="text-center">

                    <span class="text-lg font-bold text-red">Application</span>
                    <h3 class="my-10 text-4xl md:text-5xl leading-tight text-black-15 font-bold tracking-tighter">Ready to Experience the Power of Productivity?</h3>
                    <p class="mb-6 mx-auto text-lg md:text-xl text-gray font-medium max-w-2xl">Download Laksha now and take the first step towards a more organized, efficient, and fulfilling life.</p>
                    <div class="flex flex-wrap justify-center mb-16">
                        <div class="w-full md:w-auto mb-3 md:mb-0 md:mr-4">
                            <a href="https://apps.apple.com/in/app/lakshyaapp/id6498630642" target="_blank" rel="noopener noreferrer">
                                <img class="mx-auto" src={store} alt="" />
                            </a>
                        </div>
                        <div class="w-full md:w-auto">
                            <a href="https://play.google.com/store/apps/details?id=com.promettur.dyscorse" target="_blank" rel="noopener noreferrer">
                                <img className="mx-auto" src={play} alt="Google Play Store" />
                            </a>
                        </div>
                    </div>
                    <div class="flex flex-row items-end mx-auto max-w-max">
                        <div class="relative">
                            <img class="mx-auto" src={downloadmockup} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApplicationSection