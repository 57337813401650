import React from 'react';
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FaInstagram } from "react-icons/fa";

const InviteFriendsModal = ({ isModalVisible, handleOk, handleCancel }) => {
  return (
    <Modal
      title="Invite Friends"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          type="primary"
          icon={<FaInstagram icon={FaInstagram} />}
          style={{ backgroundColor: '#25D366', borderColor: '#25D366' }}
          href="https://wa.me/?text=Invite your friends to this awesome app!"
          target="_blank"
        >
          WhatsApp
        </Button>
        <Button
          type="primary"
        //   icon={<FontAwesomeIcon icon={faInstagram} />}
          style={{ backgroundColor: '#E4405F', borderColor: '#E4405F' }}
          href="https://www.instagram.com/?url=Invite your friends to this awesome app!"
          target="_blank"
        >
          Instagram
        </Button>
        <Button
          type="primary"
        //   icon={<FontAwesomeIcon icon={faFacebook} />}
          style={{ backgroundColor: '#3b5998', borderColor: '#3b5998' }}
          href="https://www.facebook.com/sharer/sharer.php?u=Invite your friends to this awesome app!"
          target="_blank"
        >
          Facebook
        </Button>
      </div>
    </Modal>
  );
};

export default InviteFriendsModal;